import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Ekologické faktory, valence a nika" />
    <h1>Ekologické faktory, valence a nika</h1>

<p><strong>Ekologick&eacute; faktory </strong>limituj&iacute; v&yacute;skyt organismů a podmiňuj&iacute; tak jejich geografick&eacute; roz&scaron;&iacute;řen&iacute;. Maj&iacute; vliv na populačn&iacute; dynamiku, např&iacute;klad na rozmnožov&aacute;n&iacute;, &uacute;mrtnost a migraci, a t&iacute;m tyto faktory způsobuj&iacute; rozd&iacute;ly v populačn&iacute;ch hodnot&aacute;ch.</p>
<p>Ekologick&eacute; faktory tak&eacute; <strong>podporuj&iacute; vznik různ&yacute;ch adaptac&iacute;</strong> (ekologick&yacute;ch forem či ekotypů) a podle toho se daj&iacute; rozdělit mimo jin&eacute; na:</p>
<ul>
<li><strong>morfoplastick&eacute; faktory</strong> (podporuj&iacute; morfologick&eacute; změny organismu)</li>
<li><strong>fyzioplastick&eacute; faktory </strong>(ovlivňuj&iacute; fyziologick&eacute; pochody, např. hospodařen&iacute; s vodou v such&yacute;ch oblastech)</li>
<li><strong>etoplastick&eacute; faktory</strong> (ovlivňuj&iacute; a podmiňuj&iacute; specifick&eacute; chov&aacute;n&iacute;)</li>
</ul>
<hr />
<h2>Členěn&iacute; ekologick&yacute;ch faktorů</h2>
<p><strong>Členěn&iacute;</strong>, kter&eacute; n&aacute;m ř&iacute;k&aacute;, jak&eacute; jsou <strong>z&aacute;kladn&iacute; ekologick&eacute; faktory</strong>, rozli&scaron;uje:</p>
<ul>
<li><strong>faktory abiotick&eacute; </strong>(působen&iacute; těchto ekologick&yacute;ch faktorů se měn&iacute; v prostoru, např. u expozice svahu, a čase, např. sezonalita)
<ul>
<li><strong>světlo</strong></li>
<li><strong>teplota</strong> (mr&aacute;z)</li>
<li><strong>sr&aacute;žky</strong></li>
<li><strong>půdn&iacute; vlhkost</strong></li>
<li><strong>půdn&iacute; reakce</strong></li>
<li><strong>typ podlož&iacute;</strong></li>
<li><strong>oheň</strong></li>
</ul>
</li>
<li><strong>faktory biotick&eacute;</strong>
<ul>
<li><strong>mezidruhov&eacute; a vnitrodruhov&eacute; vztahy</strong></li>
<li><strong>populačn&iacute; dynamika</strong></li>
<li><strong>antropogenn&iacute; vlivy</strong></li>
</ul>
</li>
</ul>
<hr />
<h2 id="ekologicka-amplituda">Ekologick&aacute; valence a amplituda</h2>
<p>Podle definice Hesseho z roku 1924, je <strong>ekologick&aacute; valence</strong><strong>druhu</strong> určena vzd&aacute;lenost&iacute; mezi minimem a maximem působen&iacute; ekologick&eacute;ho faktoru. Ekologick&aacute; valence je tedy rozmez&iacute; určit&eacute;ho faktoru (např.&nbsp;teplota), v jak&eacute;m je druh schopen přež&iacute;vat.</p>
<p><strong>Ekologick&aacute; amplituda</strong> je definov&aacute;na jako m&iacute;ra tolerance organismu vůči vněj&scaron;&iacute;m podm&iacute;nk&aacute;m, v podstatě jde tedy o pojem, kter&yacute; se použ&iacute;v&aacute; s ekologickou valenc&iacute; zaměnitelně.</p>
<p>Druhy organismů lze dělit podle vztahu k ekologick&eacute; valenci (či amplitudě) v&nbsp;z&aacute;kladu&nbsp;na:</p>
<ul>
<li><strong>stenovalentn&iacute; druhy</strong> = organismy s &uacute;zkou valenc&iacute; k určit&eacute;mu ekologick&eacute;mu faktoru (specializovan&eacute; na specifick&eacute; podm&iacute;nky)</li>
<li><strong>euryvalentn&iacute; druhy</strong> = organismy s &scaron;irokou valenc&iacute; k určit&eacute;mu ekologick&eacute;mu faktoru (schopn&eacute; přež&iacute;vat v rozličn&yacute;ch podm&iacute;nk&aacute;ch)</li>
</ul>
<hr />
<h2>Koncept ekologick&eacute; niky</h2>
<p><strong>Ekologick&aacute; nika </strong>je podle původn&iacute; definice Grinella (1917) <strong>soubor podm&iacute;nek ve&nbsp;kter&yacute;ch může dan&yacute; druh existovat</strong>. Těmito podm&iacute;nkami se rozum&iacute; zejm&eacute;na fyziologick&aacute; tolerance k faktorům prostřed&iacute;, morfologick&eacute; limity organismu a interakce s jin&yacute;mi organismy.</p>
<p>Dal&scaron;&iacute; definice z roku 1924 od Eltona podtrhuje potravn&iacute; chov&aacute;n&iacute; druhů a&nbsp;vliv druhu na&nbsp;prostřed&iacute;, ve kter&eacute;m žije. Ekologickou niku vyobrazuje jako funkčn&iacute; roli druhu v potravn&iacute;m řetězci a dopad t&eacute;to role na prostřed&iacute;.</p>
<p>Hutchinson (1944) pot&eacute; mluv&iacute; o ekologick&eacute; nice jako o sumě v&scaron;ech ekologick&yacute;ch faktorů, kter&eacute; působ&iacute; na organismus. Nika v tomto pojet&iacute; je pak definovan&aacute; jako region n-dimenzn&iacute;ho superobjemu, ve kter&eacute;m je n-množstv&iacute; os tvořeno gradienty různ&yacute;ch ekologick&yacute;ch faktorů.</p>
<p>V konceptu ekologick&eacute; niky rozli&scaron;ujeme:</p>
<ul>
<li><strong>ekologickou niku fundament&aacute;ln&iacute;</strong> = ekologick&aacute; nika teoretick&aacute;, bez vlivu konkurenčn&iacute;ch organismů</li>
<li><strong>ekologickou niku realizovanou</strong> = ekologick&aacute; nika skutečně realizovan&aacute;, zmen&scaron;en&aacute; v někter&yacute;ch oblastech vlivem konkurenčn&iacute;ch druhů</li>
</ul>
<hr />
<h2>Ekologick&eacute; z&aacute;kony</h2>
<h3>Z&aacute;kon rozd&iacute;ln&eacute;ho ekologick&eacute;ho a&nbsp;fyziologick&eacute;ho optima</h3>
<p>Ellenbergův z&aacute;kon rozd&iacute;ln&eacute;ho ekologick&eacute;ho a fyziologick&eacute;ho optima prav&iacute;, že ekologick&aacute; amplituda druhu je jin&aacute; (zpravidla už&scaron;&iacute;) než potencion&aacute;ln&iacute; fyziologick&aacute; amplituda. To je zpravidla způsobeno konkurenčn&iacute;mi organismy, kter&eacute; jsou v dan&yacute;ch podm&iacute;nk&aacute;ch &uacute;spě&scaron;něj&scaron;&iacute;.</p>
<h3>Liebigův z&aacute;kon minima</h3>
<p>Liebigův z&aacute;kon minima vznikl již v roce 1840 a ř&iacute;k&aacute;, že růst rostlin je limitov&aacute;n prvkem s nejniž&scaron;&iacute; dostupnost&iacute;. Později byl z&aacute;kon minima roz&scaron;&iacute;řen na obecn&yacute; model limituj&iacute;c&iacute;ch faktorů pro v&scaron;echny organismy.</p>
<h3>Z&aacute;kon substituce faktorů</h3>
<p>Ekologick&yacute; z&aacute;kon substituce faktorů vyj&aacute;dřil v roce 1925 Lundeg&aring;rdh a č&aacute;stečně jde proti z&aacute;konu minima. Tvrd&iacute; totiž, že nedostatek někter&yacute;ch z ekologick&yacute;ch faktorů může b&yacute;t nahrazen někter&yacute;mi jin&yacute;mi, kter&eacute; jsou dostatečně abundantn&iacute;. Např&iacute;klad přebytek světla může č&aacute;stečně kompenzovat nedostatek tepla.</p>
<hr />
<h2 id="ellenberg">Ellenbergovy ekologick&eacute; indikačn&iacute; hodnoty</h2>
<p><strong>Ellenbergovy ekologick&eacute; indikačn&iacute; hodnoty</strong> (zkratka <strong>EIH</strong>) jsou vyj&aacute;dřeny pomoc&iacute; ordin&aacute;ln&iacute;ch stupnic, kter&eacute; byly definov&aacute;ny Ellenbergem a jeho kolegy v roce 1991. Tyto indikačn&iacute; hodnoty ukazuj&iacute; optimum druhu na gradientu sedmi ekologick&yacute;ch faktorů. Hodnoty jednotliv&yacute;ch taxonů <a href="https://doi.org/10.23855/preslia.2018.083">upravili a&nbsp;roz&scaron;&iacute;řili pro česk&eacute; prostřed&iacute;</a> Chytr&yacute; a jeho kolegov&eacute; v roce 2018. Pro jednotliv&eacute; druhy česk&eacute; flory je lze zjistit na str&aacute;nk&aacute;ch <a href="http://pladias.cz">Pladias</a>.</p>
<p>Ellenbergovy indikačn&iacute; hodnoty jsou vyj&aacute;dřeny pro tyto ekologick&eacute; faktory:</p>
<ul>
<li><strong>světlo</strong> (L 1-9; vy&scaron;&scaron;&iacute; hodnoty znamenaj&iacute; vy&scaron;&scaron;&iacute; n&aacute;roky na světlo, u stromů se vztahuj&iacute; k mlad&yacute;m jedincům v bylinn&eacute;m nebo keřov&eacute;m patře)</li>
<li><strong>teplota</strong> (T 1-9; vy&scaron;&scaron;&iacute; hodnota znamen&aacute; n&aacute;rok na vy&scaron;&scaron;&iacute; teplotu)</li>
<li><strong>vlhkost</strong> (M 1-12; vy&scaron;&scaron;&iacute; hodnota znamen&aacute; n&aacute;rok na vy&scaron;&scaron;&iacute; vlhkost, hodnoty 10&nbsp;až&nbsp;12 pro vodn&iacute; rostliny)</li>
<li><strong>reakce</strong> (R 1-9; vy&scaron;&scaron;&iacute; hodnota znamen&aacute; vazbu na bazičtěj&scaron;&iacute; prostřed&iacute;)</li>
<li><strong>živiny</strong> (N 1-9; vy&scaron;&scaron;&iacute; hodnoty vyjadřuj&iacute; vy&scaron;&scaron;&iacute; n&aacute;roky na dostupnost živin, zejm&eacute;na N&nbsp;a&nbsp;P)</li>
<li><strong>salinita</strong> (S 0-9; vysok&eacute; hodnoty vyjadřuj&iacute; vy&scaron;&scaron;&iacute; toleranci rostlin k vět&scaron;&iacute;m koncentrac&iacute;m rozpustn&yacute;ch sol&iacute;)</li>
<li><strong>kontinentalita</strong> (K 1-9; vy&scaron;&scaron;&iacute; hodnota vyjadřuje vy&scaron;&scaron;&iacute; preferenci kontinent&aacute;ln&iacute;ho prostřed&iacute;)</li>
</ul>
<p>Ellenbergova indikačn&iacute; hodnota může b&yacute;t opatřena symbolem 'x' označuj&iacute;c&iacute; generalistu v dan&eacute;m ekologick&eacute;m faktoru, tedy rostlinu se &scaron;irokou ekologickou valenc&iacute; v konkr&eacute;tn&iacute;m faktoru prostřed&iacute;.</p>
<hr />
  <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Ekologické faktory, valence a nika</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/ekologicka/"><button className="inv">&larr; Ekologick&aacute; biogeografie</button></Link>
    <Link to="/biogeografie/ekologicka/abioticke-faktory"><button className="inv">Abiotick&eacute; ekologick&eacute; faktory &rarr;</button></Link>
    </BtnWrap>
  </div></ImgCon><hr />
  </Layout>
)
